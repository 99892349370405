<template>
    <div class="all-medal-content md:flex-col md:gap-16">
        <div class="content-menu md:w-full md:min-w-full md:max-w-full md:flex-row">
            <div class="menu-item md:w-auto md:px-10 md:py-2 md:h-36 md:rounded-full" :class="{ 'menu-item-active': tab === 1 }" @click="changeActive(1)">
                <img v-show="tab != 1" src="https://s3.us-west-1.amazonaws.com/agi.love/img/all.svg" alt="" />
                <img v-show="tab == 1" src="https://s3.us-west-1.amazonaws.com/agi.love/img/all-active.svg" alt="" />
                <span>{{lang=='en'?'My Community':'我的社区'}}</span>
            </div>
            <div class="menu-item md:w-auto md:px-10 md:py-2 md:h-36 md:rounded-full" :class="{ 'menu-item-active': tab === 2 }" @click="changeActive(2)">
                <img v-show="tab != 2" src="https://s3.us-west-1.amazonaws.com/agi.love/img/owned.svg" alt="" />
                <img v-show="tab == 2" src="https://s3.us-west-1.amazonaws.com/agi.love/img/owned-active.svg" alt="" />
                <span>{{lang=='en'?'Invite friends':'邀请好友'}}</span>
            </div>
        </div>
        <teamContent v-if="tab == 1" />
        <ShareContent v-if="tab == 2" />
    </div>
</template>
<script>
import ShareContent from './ShareContent.vue';
import teamContent from './teamContent.vue';
import { mapState } from 'vuex';
export default {
    components: {
        ShareContent,
        teamContent,
    },
    data() {
        return {
            tab: 1,
        };
    },
    computed: {
        ...mapState(['screenWidth', 'address','lang']),
    },
    async created() {},
    mounted() {
    },
    watch: {},
    methods: {
        changeActive(val) {
            this.tab = val;
        },
    },
};
</script>
<style lang="scss" scoped>
.all-medal-content {
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 80px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 50px 75px 80px;
    display: flex;
    gap: 40px;

    @screen md {
        padding: 16px;
    }

    .content-menu {
        /* margin-right: 40px; */
        width: 196px;
        min-width: 196px;
        max-width: 196px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .menu-item {
            cursor: pointer;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;
            border-radius: 8px;
            transition: all 0.3s;

            img {
                width: 24px;
                margin-right: 12px;
            }

            span {
                display: inline-block;
                color: #a2a3a5;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 16px;
                /* 100% */
                letter-spacing: -0.4px;
            }
        }

        .menu-item-active {
            background: rgba(255, 255, 255, 0.1);

            span {
                color: #fff;
            }
        }
    }
}
</style>
