<template>
    <div class="all-medal-content md:flex-col md:gap-16">
        <div class="content-main md:grid md:grid-cols-1">
            <div class="imgBox">
                <img v-if="lang=='en'" src="../../assets/img/share/share_en.png" alt="" />
                <img v-else src="../../assets/img/share/share_zhcn.png" alt="" />
                <button class="copyBtn" v-copy="shareUrl">{{lang=='en'?'Copy':'复制'}}</button>
                <div class="qrcodeBox md:flex-col">
                    <p class="ptext">AJ Labs {{lang=='en'?'Welcome to your arrival':'欢迎您的到来'}} >>></p>
                    <div class="qrcode">
                        <div id="qrcode"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import QRCode from 'qrcodejs2'; // 引入qrcode
export default {
    data() {
        return {
            popupItem: {},
            active: 'all',
        };
    },
    computed: {
        ...mapState(['isConnect', 'address', 'sbtMedal', 'linkURL','lang']),
        shareUrl() {
            return this.linkURL + '/Community?address=' + this.address;
        },
    },

    async created() {},
    mounted() {
        this.$nextTick(() => {
            this.qrcode();
        });
    },
    watch: {
        appUrl() {
            this.$nextTick(() => {
                this.qrcode();
            });
        },
    },
    methods: {
        qrcode() {
            document.getElementById('qrcode').innerHTML = '';
            let qrcode = new QRCode('qrcode', {
                width: 140,
                height: 140,
                colorDark: '#000',
                colorLight: '#fff',
                text: this.shareUrl, // 设置二维码内容或跳转地址
                correctLevel: QRCode.CorrectLevel.L, //容错级别L7% M15% Q25% H30%
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.all-medal-content {
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 80px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px !important;
    display: flex;
    gap: 0px !important;
    @screen md {
        padding: 16px;
    }

    .content-menu {
        /* margin-right: 40px; */
        width: 196px;
        min-width: 196px;
        max-width: 196px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .menu-item {
            cursor: pointer;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;
            border-radius: 8px;
            transition: all 0.3s;

            img {
                width: 24px;
                margin-right: 16px;
            }

            span {
                display: inline-block;
                color: #a2a3a5;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 16px;
                /* 100% */
                letter-spacing: -0.4px;
            }
        }

        .menu-item-active {
            background: rgba(255, 255, 255, 0.1);

            span {
                color: #fff;
            }
        }
    }

    .content-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 24px;
        flex: 1;
        .imgBox {
            width: 100%;
            text-align: center;
            min-height: 500px;
            background: #0e0e0e;
            border-radius: 20px;
            border: 1px solid #3d3d43;
            display: flex;
            flex-direction: column;
            align-items: center;
            & > img {
                margin-top: 40px;
                width: 50%;
            }

            .copyBtn {
                margin: 40px 0px;
                border: 1px solid #0421df;
                height: 44px;
                border-radius: 8px;
                width: 50%;
            }
            .qrcodeBox {
                width: 80%;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding-bottom: 40px;
                .ptext {
                    width: 304px;
                    height: 40px;
                    background: linear-gradient(-1deg, #0d319a, #1855bc);
                    border-radius: 18px;
                    line-height: 40px;
                }
                & > .qrcode {
                    margin-top: 40px;
                    // padding: 40px;
                    // margin-bottom: 0px;
                    // background: url('../../assets/img/share/codebg.png') no-repeat;
                    // background-size: 100% 100%;
                    display: flex;

                    & > div {
                        border-radius: 12px;
                        background: url('../../assets/img/share/codebox.png') no-repeat;
                        // background-color: #fff;
                        background-size: 100% 100%;
                        padding: 20px;
                        display: flex;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
@media (max-width: 480px) {
    .copyBtn {
        width: 70% !important;
				margin-top: 30px !important;
    }
    .imgBox {
        & > img {
            margin-top: 40px !important;
            width: 80% !important;
        }
    }
    .qrcodeBox {
        display: flex;
        flex-direction: column !important;
				align-items: center !important;
    }
}
</style>
