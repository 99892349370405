<template>
    <div class="all-medal-content2 flex-column">
        <div class="grid-container" :class="[screenWidth > 1000 ? '' : 'grid-container2']">
            <div class="box2 item2">
                <img src="../../assets/img/team/selfyj.png" alt="" />
                <p>
                    <span>{{lang=='en'?'Total team performance ':'总团队业绩 '}}/ USDT</span>
                    <span>{{ mytotal.teamamount }}</span>
                </p>
            </div>
            <div class="box2 item2">
                <img src="../../assets/img/team/dqyj.png" alt="" />
                <p>
                    <span>{{lang=='en'?'Regional performance':'大区业绩 '}}/ USDT</span>
                    <span>{{ mytotal.bigamount }}</span>
                </p>
            </div>
            <div class="box2 item2">
                <img src="../../assets/img/team/xqyj.png" alt="" />
                <p>
                    <span>{{lang=='en'?'Community performance':'小区业绩 '}}/ USDT</span>
                    <span>{{ $calc.Sub( mytotal.teamamount,mytotal.bigamount) }}</span>
                </p>
            </div>
            <div class="box2 item2">
                <img src="../../assets/img/team/selfyj.png" alt="" />
                <p>
                    <span>{{lang=='en'?'Userlevel':'个人等级'}}</span>
                    <span>V{{ info.userlevel }}</span>
                </p>
            </div>
        </div>
        <div class="flex-1 teamList">
            <div class="flex-between">
                <p class="flex">
                    <img src="../../assets/img/stack/amount.png" alt="" />
                    <span>{{lang=='en'?'My team':'我的团队'}}</span>
                </p>
                <custom-pagination v-if="totalCount" :totalItems="totalCount" :itemsPerPage="pageSize" @page-change="onChange" />
            </div>
            <ul v-if="arrList.length > 0" class="grid-container3" :class="[screenWidth > 900 ? 'grid-container3' : 'mb12']">
                <li class="box3" v-for="item in arrList">
                    <div class="flex teamTitle">
                        <p class="flex-1">
                            <span>{{lang=='en'?'Address:':'地址：'}}</span>
                            <span v-if="screenWidth > 900">{{ item.address }}</span>
                            <span v-else>{{ item.address | addressF }}</span>
                        </p>
                        <span>{{lang=='en'?'Level:':'等级：'}}V {{ item.userlevel }}</span>
                    </div>
                    <div class="teamItem" :class="[screenWidth > 900 ? 'grid-container4' : 'grid-container4']">
                        <p class="flex" :class="[screenWidth > 900 ? '' : ' jc-center']">
                            <img class="mr-20" v-if="screenWidth > 900" src="../../assets/img/team/team1.png" alt="" />
                            <span class="flex-column" :class="[screenWidth > 900 ? '' : 'ai-center']">
                                <span>{{lang=='en'?'Personal performance':'个人业绩'}}</span>
                                <span>{{ item.selfamount }}</span>
                            </span>
                        </p>
                        <p class="flex" :class="[screenWidth > 900 ? '' : ' jc-center']">
                            <img class="mr-20" v-if="screenWidth > 900" src="../../assets/img/team/team1.png" alt="" />
                            <span class="flex-column" :class="[screenWidth > 900 ? '' : 'ai-center']">
                                <span>{{lang=='en'?'Team performance':'团队业绩'}}</span>
                                <span>{{ item.teamamount }}</span>
                            </span>
                        </p>
                        <!-- <p class="flex">
                            <img v-if="screenWidth > 900" src="../../assets/img/team/team1.png" alt="" />
                            <span class="flex-column" :class="[screenWidth > 900 ? '' : 'ai-center']">
                                <span>{{lang=='en'?'Community performance':'小区业绩'}}</span>
                                <span>{{ $calc.Sub(item.teamamount, item.bigamount) }}</span>
                            </span>
                        </p> -->
                    </div>
                </li>
            </ul>
            <empty :data="arrList" />
        </div>
    </div>
</template>
<script>
import CustomPagination from '@/components/CustomPagination';
import empty from '@/components/empty';
import { mapState } from 'vuex';
import { mytotal, teamtotal } from '@/api/Users.js';
export default {
    data() {
        return {
            mytotal: {},
            arrList: [],
            pageIndex: 1,
            pageSize: 5,
            totalCount: 0,
        };
    },
    components: { empty, CustomPagination },
    filters: {
        addressF(v) {
            if (!v) {
                return '-';
            }
            return v.replace(/^([A-Za-z0-9]{5})[A-Za-z0-9]{33}([A-Za-z0-9]{4})$/, '$1…$2');
        },
    },
    computed: {
        ...mapState(['screenWidth', 'address', 'info','lang']),
    },

    async created() {},
    async mounted() {
        this.init();
    },
    watch: {},
    methods: {
        async onChange(e) {
            this.pageIndex = e;
            this.getTeamList();
        },
        async init() {
            this.mytotal = (await mytotal()).data;
            this.getTeamList();
        },
        async getTeamList() {
            let res = await teamtotal({
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            });
            this.totalCount = res.totalCount;
            this.arrList = [];
            this.arrList = res.list;
        },
    },
};
</script>
<style lang="scss" scoped>
.all-medal-content2 {
    width: 100%;
    flex: 1;
    display: flex;
    margin: 0px 0px !important;
}
.box3 {
    background: #1a1a1d;
    border-radius: 15px;
    padding: 16px 20px;
    & > div:nth-of-type(1) {
        border-bottom: 1px solid #313131;
    }
}
.mb12 {
    row-gap: 12px !important;
}
.box2 {
    height: 100px;
    background: linear-gradient(90deg, #041cde 0%, #4b7bf8 100%);
    border-radius: 20px;
}
.grid-container2 {
    row-gap: 12px !important;
    grid-template-columns: repeat(1, 1fr) !important;
}
.grid-container3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    .item2 {
        display: flex;
        align-items: center;
        padding: 16px 20px;
        img {
            width: 50px;
            height: 50px;
        }
        p {
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            margin-bottom: 0px !important;
            & > span:nth-of-type(1) {
                font-size: 14px;
                color: rgba($color: #fff, $alpha: 0.8);
            }
            & > span:nth-of-type(2) {
                font-size: 20px;
            }
        }
    }
}
.teamList {
    margin-top: 30px;
    & > div:nth-of-type(1) {
        padding-bottom: 20px;
        & > p {
            margin-bottom: 0px !important;
            img {
            }
            span {
                padding-left: 12px;
                font-size: 16px;
                color: #fff;
            }
        }
    }
}
.grid-container4 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.teamItem {
    padding-top: 20px;
    & > p {
        margin-bottom: 0px !important;
        img {
            width: 42px;
            height: 42px;
        }
        & > span {
            // padding-left: 12px;
            display: flex;
            flex-direction: column;
            & > span:nth-of-type(1) {
                font-size: 14px;
                color: rgba($color: #fff, $alpha: 0.8);
            }
            & > span:nth-of-type(2) {
                font-size: 20px;
            }
        }
    }
}
.teamTitle {
    & > p {
        font-size: 15px;
    }
    & > span {
        margin-bottom: 20px;
    }
}
.ai-center {
    align-items: center;
}
.paginationBox {
    .ant-pagination-simple-pager {
        color: #fff;
    }
}
.jc-center{
    justify-content: center;
}
</style>
